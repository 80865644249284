import { gql } from '@apollo/client';

import { SALE_CORE_FIELDS } from 'src/components/Sales/queries';

const BID_CORE_FIELDS = gql`
  fragment BidCoreFields on Bid {
    id
    price
    note
    paddleNumber
    paddleService { id, name, code }
    removedAt
  }
`;

export const AUDIO_STREAM_CONFIG = gql`
  query AudioStreamConfig {
    audioStreamConfig {
      accessKeyId
      secretAccessKey
      channelArn
      region
    }
  }
`;

const SALE_ASSET_BIDDING_FIELDS = gql`
  fragment SaleAssetBiddingFields on SaleAsset {
    id
    lotNumber
    biddingSoldAmount
    biddingSoldBuyerDetails
    biddingStatus
    biddingNext
    biddingStep
    pendingSellerConfirmation
    pendingBidGroupId
    watching
    asset {
      id
      name
      vin
      cityState
      primaryPhotoUrl
      webPath
      announcements {
        id
        body
        acceptances {
          id
          buyerUser {
            id
          }
        }
      }
    }
    activeOffer {
      buyer { id }
    }
  }
`;

// NOTE (Jared E Morgan, 2024-02-27) - THIS IS IMPORTANT!
// bidGroupSaleAssets MUST be above biddingSaleAsset in the list of fields in
// this fragment. useSubscription(BIDDING_UPDATED) updates only the
// biddingSaleAsset, but if this sale asset is part of a bidding group, the
// cache update sees the old information from bidGroupSaleAssets and reverts the
// data back to the old information. By having the cache process the old data
// first, the biddingSaleAsset updates win out due to being the last update.
// TODO Actually update the grouped sale asset in useSubscriptions so this is
// not a problem.
const BIDDING_SALE_FIELDS = gql`
  ${SALE_CORE_FIELDS}
  ${SALE_ASSET_BIDDING_FIELDS}
  ${BID_CORE_FIELDS}
  fragment BiddingSaleFields on Sale {
    bidGroupSaleAssets {
      ...SaleAssetBiddingFields
    }
    ...SaleCoreFields
    lotsRemainingLabel
    biddingUpdatedTimestamp
    activeStream
    viewerRoomCode
    biddingSaleAsset {
      ...SaleAssetBiddingFields
      highBid
      bids {
        ...BidCoreFields
        buyer { id, city, state }
      }
    }
    priorSaleAsset {
      ...SaleAssetBiddingFields
    }
  }
`;

export const SALE = gql`
  ${BIDDING_SALE_FIELDS}
  query Sale($id: ID!) {
    sale(id: $id) {
      ...BiddingSaleFields
    }
  }
`;

export const SALE_UPDATED = gql`
  ${BIDDING_SALE_FIELDS}
  subscription SaleUpdated($id: ID!) {
    saleUpdated(id: $id) {
      sale {
        ...BiddingSaleFields
      }
    }
  }
`;

export const BIDDING_SALE_ASSETS = gql`
  ${SALE_ASSET_BIDDING_FIELDS}
  query BiddingSaleAssets(
    $saleId: ID
  ) {
    saleAssets(
      saleId: $saleId
    ) {
      ...SaleAssetBiddingFields
    }
  }
`;

export const BIDDING_SALE_ASSET_UPDATED = gql`
  ${SALE_ASSET_BIDDING_FIELDS}
  subscription BiddingSaleAssetUpdated($saleId: ID!) {
    saleAssetUpdated(saleId: $saleId, scope: "bidding") {
      saleAsset {
        ...SaleAssetBiddingFields
      }
    }
  }
`;

export const BIDDING_UPDATED = gql`
  ${BID_CORE_FIELDS}
  subscription BiddingUpdated($saleId: ID!) {
    biddingUpdated(saleId: $saleId) {
      saleAssetId
      timestamp

      biddingNextUpdated
      biddingNext
      biddingNextManual
      biddingStep

      bidsUpdated
      nextRemovedBid { id }
      highBid
      bids {
        ...BidCoreFields
        buyer { id, city, state, cityState }
        backgroundColor
        foregroundColor
      }

      biddingFlashUpdated
      biddingFlash
      biddingFlashBackgroundColor
      biddingFlashForegroundColor
      biddingBuyerBackgroundColor
      biddingBuyerForegroundColor
      biddingFlashFades
      biddingFlashType

      biddingStatusUpdated
      biddingStatus

      biddingNoticeUpdated
      biddingNotice

      audioStreamUpdated
      activeStream
    }
  }
`;

export const CREATE_BID = gql`
  ${BID_CORE_FIELDS}
  mutation CreateBid(
    $saleAssetId: ID!
    $price: Decimal!
  ) {
    createBid(
      saleAssetId: $saleAssetId
      price: $price
    ) {
      bid {
        ...BidCoreFields
      }
      errors { path, message }
    }
  }
`;

export const BIDDING_ASSET = gql`
  query Asset($id: ID!) {
    asset(id: $id) {
      id
      name
      vin
      primaryPhotoUrl
      generalDetails
      soarrPhotoUrls
      miles
      hours
      notes
      photos {
        id
        filename
        contentType
        url
      }
    }
  }
`;

export const CHOOSE_BID_GROUP = gql`
  mutation ChooseBidGroup(
    $id: ID!
    $saleAssetIds: [ID!]!
  ) {
    chooseBidGroup(
      id: $id
      saleAssetIds: $saleAssetIds
    ) {
      errors { path, message }
    }
  }
`;

export const START_BIDDING_VISIT = gql`
  mutation StartBiddingVisit(
    $saleId: ID!
    $page: String!
  ) {
    startBiddingVisit(
      saleId: $saleId
      page: $page
    ) {
      biddingVisit { id }
      errors { path, message }
    }
  }
`;

export const END_BIDDING_VISIT = gql`
  mutation EndBiddingVisit($id: ID!) {
    endBiddingVisit(id: $id) {
      biddingVisit { id }
      errors { path, message }
    }
  }
`;

export const BIDDING_VISIT_CHECK_IN = gql`
  mutation BiddingVisitCheckIn(
    $id: ID!
    $audio: Boolean!
  ) {
    biddingVisitCheckIn(
      id: $id
      audio: $audio
    ) {
      errors { path, message }
    }
  }
`;
